import React from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import Facebook from '../icons/Facebook';
import Github from '../icons/Github';
import Instagram from '../icons/Instagram';
import Linkedin from '../icons/Linkedin';
import Medium from '../icons/Medium';
import Twitter from '../icons/Twitter';

const Socials: React.FC = () => {
    return (
        <SocialGrid>
            <a href="https://www.github.com/oberonamsterdam/" target="_blank">
                <Github />
            </a>
            <a href="https://www.instagram.com/oberonamsterdam/" target="_blank">
                <Instagram />
            </a>
            <a href="https://medium.com/oberonamsterdam/" target="_blank">
                <Medium />
            </a>
            <a href="https://www.facebook.com/oberonamsterdam/" target="_blank">
                <Facebook />
            </a>
            <a href="https://twitter.com/oberonamsterdam/" target="_blank">
                <Twitter />
            </a>
            <a href="https://www.linkedin.com/company/oberonamsterdam/" target="_blank">
                <Linkedin />
            </a>
        </SocialGrid>
    );
};

const SocialGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 1.6rem;
    place-items: start;
    max-width: 20rem;

    @media screen and (${theme.mediaQueries.vertical}) {
        grid-row-gap: 1.4rem;
        margin: 1.4rem 0;
    }
`;

export default Socials;
