import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Linkedin = ({ color = 'currentColor', ...rest }: Props) => (
    <svg width="24px" height="22px" viewBox="0 0 24 22">
        <title>Linkedin</title>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Footer-person" transform="translate(-1659.000000, -745.000000)" fill="#191E21">
                <g id="Footer" transform="translate(-386.000000, -1706.000000)">
                    <g id="Group-5" transform="translate(1905.264828, 1972.000000)">
                        <g id="Group-3">
                            <g id="Group-4" transform="translate(1.735172, 436.000000)">
                                <g id="Icon-/-Linked-In" transform="translate(138.712651, 42.966102)">
                                    <g id="Group">
                                        <path
                                            d="M23.1578947,13.4883023 L23.1578947,22 L18.194386,22 L18.194386,14.0582558 C18.194386,12.0631628 17.4764912,10.7017209 15.6802105,10.7017209 C14.3084912,10.7017209 13.4923041,11.6193256 13.1333567,12.507 C13.002386,12.8242093 12.9686784,13.266 12.9686784,13.710093 L12.9686784,22 L8.00336842,22 C8.00336842,22 8.07026901,8.54930233 8.00336842,7.15562791 L12.9679064,7.15562791 L12.9679064,9.25969767 C12.9578713,9.27555814 12.9447485,9.29244186 12.9352281,9.3077907 L12.9679064,9.3077907 L12.9679064,9.25969767 C13.6273918,8.24974419 14.805614,6.80695349 17.442269,6.80695349 C20.7088187,6.80695349 23.1578947,8.92867442 23.1578947,13.4883023 L23.1578947,13.4883023 Z"
                                            id="Path"
                                            fillRule="nonzero"
                                        />
                                        <path
                                            d="M2.80956725,0 C1.11106433,0 -3.89466237e-13,1.10767442 -3.89466237e-13,2.56427907 C-3.89466237e-13,3.98916279 1.07890058,5.13034884 2.74369591,5.13034884 L2.77688889,5.13034884 C4.50832749,5.13034884 5.58516959,3.9894186 5.58516959,2.56427907 C5.55249123,1.10767442 4.50832749,0 2.80956725,0 L2.80956725,0 Z"
                                            id="Path"
                                            fillRule="nonzero"
                                        />
                                        <polygon
                                            id="Path"
                                            fillRule="nonzero"
                                            points="0.294877193 22 5.25812865 22 5.25812865 7.15562791 0.294877193 7.15562791"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Linkedin;
