import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Github = ({ color = 'currentColor', ...rest }: Props) => (
    <svg width="22px" height="22px" viewBox="0 0 22 22">
        <title>Github</title>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Footer-person" transform="translate(-1521.000000, -702.000000)" fill="#191E21">
                <g id="Footer" transform="translate(-386.000000, -1706.000000)">
                    <g id="Group-5" transform="translate(1905.264828, 1972.000000)">
                        <g id="Group-3">
                            <g id="Group-4" transform="translate(1.735172, 436.000000)">
                                <g id="Icon-/-Git" transform="translate(0.000000, -0.000000)">
                                    <g id="github-345c95fb20d42b29">
                                        <path
                                            d="M10.9995417,0 C4.92479167,0 0,5.04923404 0,11.2794468 C0,16.2617447 3.1515,20.4890213 7.52308333,21.9803404 C8.07308333,22.0842553 8.27429167,21.736 8.27429167,21.4373617 C8.27429167,21.1691489 8.26466667,20.46 8.25870833,19.5191489 C5.198875,20.2006809 4.55308333,18.007234 4.55308333,18.007234 C4.0535,16.7040851 3.33254167,16.357234 3.33254167,16.357234 C2.333375,15.6574468 3.40816667,15.6714894 3.40816667,15.6714894 C4.511375,15.7510638 5.09208333,16.8337447 5.09208333,16.8337447 C6.07429167,18.5577021 7.667,18.0601277 8.294,17.7708511 C8.39345833,17.0420426 8.67808333,16.5449362 8.9925,16.2631489 C6.54958333,15.9780851 3.982,15.0110213 3.982,10.6891915 C3.982,9.45812766 4.41008333,8.4512766 5.11408333,7.66255319 C5.000875,7.37702128 4.62366667,6.23068085 5.22179167,4.67757447 C5.22179167,4.67757447 6.14533333,4.3747234 8.24679167,5.83374468 C9.1245,5.58378723 10.065,5.45880851 11.0004583,5.45412766 C11.935,5.45880851 12.8759583,5.58378723 13.7550417,5.83374468 C15.8542083,4.3747234 16.776375,4.67757447 16.776375,4.67757447 C17.3767917,6.23068085 16.999125,7.37702128 16.8859167,7.66255319 C17.5908333,8.4512766 18.016625,9.45765957 18.016625,10.6891915 C18.016625,15.0217872 15.4449167,15.9752766 12.9946667,16.2547234 C13.3888333,16.6029787 13.7412917,17.2905957 13.7412917,18.3433191 C13.7412917,19.8505532 13.7275417,21.0671064 13.7275417,21.4373617 C13.7275417,21.7388085 13.9255417,22.0898723 14.4837917,21.9789362 C18.85125,20.4848085 22,16.2603404 22,11.2794468 C22,5.04923404 17.07475,0 10.9995417,0"
                                            id="Github"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Github;
