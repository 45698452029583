import React, { SVGAttributes } from 'react';
import styled from 'styled-components';

const C2aButton = (props: SVGAttributes<SVGElement>) => (
    <Wrapper {...props} width="40px" height="40px" viewBox="0 0 40 40">
        <title>c2a Button</title>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Footer-person" transform="translate(-189.000000, -664.000000)">
                <g id="Footer" transform="translate(-386.000000, -1706.000000)">
                    <g id="Group" transform="translate(575.000000, 2146.000000)">
                        <g id="ATOM-/-Go-button-blk" transform="translate(0.000000, 224.000000)">
                            <g id="Group-2">
                                <circle id="Oval" fill="#000000" fillRule="nonzero" cx="20" cy="20" r="20" />
                                <g
                                    id="Group"
                                    transform="translate(13.333333, 14.166667)"
                                    fillRule="nonzero"
                                    stroke="#FFF251"
                                    strokeWidth="2"
                                >
                                    <polyline
                                        id="Path-2"
                                        points="7.08333333 11.2083333 12.4583333 5.83333333 7.08333333 0.458333333"
                                    />
                                    <path d="M12.4583333,5.83333333 L0.416666667,5.83333333" id="Path-3" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </Wrapper>
);

const Wrapper = styled.svg`
    margin-right: 1rem;
    vertical-align: middle;
    display: inline;
`;

export default C2aButton;
