import React, { FC } from 'react';

interface Props {
    diameter: number;
    fill: string;
    className?: string;
    circleRef?: React.RefObject<SVGSVGElement>;
}

const Circle: FC<Props> = ({ diameter, fill, className, circleRef }) => {
    return (
        <svg
            ref={circleRef && circleRef}
            className={className}
            width={diameter + 'vh'}
            height={diameter + 'vh'}
            viewBox={`0 0 100 100`}
        >
            <circle fill={fill} cx="50" cy="50" r="50" />
        </svg>
    );
};

export default Circle;
