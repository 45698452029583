import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Facebook = ({ color = 'currentColor', ...rest }: Props) => (
    <svg width="23px" height="23px" viewBox="0 0 23 23">
        <title>Facebook</title>
        <g id="Symbols" stroke="none" strokeWidth="1" fill={color} fillRule="evenodd">
            <g id="Footer-person" transform="translate(-1521.000000, -744.000000)" fill="#191E21">
                <g id="Footer" transform="translate(-386.000000, -1706.000000)">
                    <g id="Group-5" transform="translate(1905.264828, 1972.000000)">
                        <g id="Group-3">
                            <g id="Group-4" transform="translate(1.735172, 436.000000)">
                                <path
                                    d="M1.20657988,42.9661017 C0.538782109,42.9709445 -1.75337105e-05,43.5140718 1.13686838e-12,44.1823729 L1.13686838e-12,63.7925424 C0.00483924111,64.4608259 0.547571728,65.0000175 1.21538703,65 L11.761952,65 L11.761952,56.4794915 L8.89962751,56.4794915 L8.89962751,53.1435593 L11.761952,53.1435593 L11.761952,50.6911864 C11.761952,47.8466102 13.4991629,46.2844068 16.0356228,46.2844068 C16.8917082,46.2807585 17.7473307,46.3248948 18.5985041,46.4166102 L18.5985041,49.4 L16.8370736,49.4 C15.4609561,49.4 15.1945397,50.0610169 15.1945397,51.0194915 L15.1945397,53.1435593 L18.4950201,53.1435593 L18.0546624,56.4794915 L15.1923379,56.4794915 L15.1923379,65 L20.802494,65 C21.4737337,65 22.017881,64.4554568 22.017881,63.7837288 L22.017881,44.1735593 C22.0130418,43.5052758 21.4703093,42.9660841 20.802494,42.9661017 L1.20657988,42.9661017 Z"
                                    id="Path"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Facebook;
