import React, { useContext } from 'react';
import { Img } from 'react-image-loading';
import styled from 'styled-components';
import { locationMaps } from '../../constants/strings';
import theme from '../../constants/theme';
import { SiteContext } from '../../contexts/SiteContext';
import BodyText from '../atoms/BodyText';
import ContentLink, { ExternalContentLink } from '../atoms/ContentLink';
import Socials from '../atoms/Socials';
import Title from '../atoms/Title';
import DutchDigitalAgencies from '../icons/DutchDigitalAgencies';
import Translate from '../Translate';

interface Props {
    hasPhoto: boolean;
}
const Details: React.FC<Props> = ({ hasPhoto }) => {
    const emerceUrl = require('../../images/Badge-E100-19-zwart-transparant-variant2.png');
    const site = useContext(SiteContext);

    return (
        <Wrapper hasPhoto={hasPhoto}>
            <FooterElementWrapper>
                <Title variant="small">
                    <Translate id="location" />
                </Title>
                <Address target="_blank" href={locationMaps}>
                    <BodyText variant="tiny">
                        Barentszplein 7
                        <br />
                        1013 NJ Amsterdam
                    </BodyText>
                </Address>
            </FooterElementWrapper>
            <FooterElementWrapper>
                <Title variant="small">
                    <Translate id="contact" />
                </Title>
                <BodyText variant="tiny">
                    <PhoneNumber href="tel:+31203449480">+31 (0)20 344 9480</PhoneNumber>
                    <br />
                    <ExternalContentLink as="a" color={theme.colors.black} href="mailto:contact@oberon.nl">
                        contact@oberon.nl
                    </ExternalContentLink>
                </BodyText>
            </FooterElementWrapper>
            <FooterElementWrapper>
                <Title variant="small">
                    <Translate id="newsletter" />
                </Title>
                <ContentLink color={theme.colors.black} to={site ? `/${site}/nieuwsbrief/` : `/nieuwsbrief/`}>
                    <Translate id="signUp" />
                </ContentLink>
            </FooterElementWrapper>
            <FooterElementWrapper>
                <Title variant="small">
                    <Translate id="details" />
                </Title>
                <BodyText variant="tiny">
                    KVK 34140409
                    <br />
                    BTW NL 8093.98.023.B01
                </BodyText>
            </FooterElementWrapper>
            <FooterElementWrapper>
                <Socials />
            </FooterElementWrapper>
            <FooterElementWrapper>
                <a title="Dutch Digital Agencies" href="https://www.dutchdigitalagencies.com/" target="_blank">
                    <DutchDigitalAgencies />
                </a>
                <a title="Emerce" href="https://emerce.nl/emerce100/" target="_blank">
                    <Emerce alt="Emerce" src={emerceUrl} />
                </a>
            </FooterElementWrapper>
            <FooterElementWrapper>
                {(!site || site === 'nl') && (
                    <BodyText variant="tiny">
                        <ContentLink color={theme.colors.black} to="/techniek/">
                            <Translate id="ourTechniques" />
                        </ContentLink>
                    </BodyText>
                )}
                <BodyText variant="tiny">
                    <ContentLink color={theme.colors.black} to="/privacy-beleid/">
                        <Translate id="privacyStatement" />
                    </ContentLink>
                </BodyText>
            </FooterElementWrapper>
        </Wrapper>
    );
};

const Address = styled.a`
    text-decoration: none;
    color: ${theme.colors.black};
`;

const FooterElementWrapper = styled.div`
    margin: 1.4vh 0;
    width: 21rem;

    @media (${theme.mediaQueries.vertical}) {
        width: 100%;
    }
`;

const Wrapper = styled.div<{ hasPhoto: boolean }>`
    padding-top: 5rem;

    @media (${theme.mediaQueries.vertical}) {
        padding-top: 4.2rem;
        align-self: flex-end;
        flex-direction: row;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        ${FooterElementWrapper} {
            :nth-of-type(-n + 2) {
                width: 50%;
            }
        }
    }

    align-self: center;
    display: flex;
    position: relative;
    grid-column: 15 / 24;
    grid-row: 1 / -1;
    flex-direction: column;
    flex-wrap: wrap;

    @media (${theme.mediaQueries.horizontal}) {
        grid-column: ${({ hasPhoto }) => (hasPhoto ? '22 / 32' : '15 / 26')};
        grid-auto-columns: 21rem;
        max-height: 70%;
    }

    @media screen and (min-height: ${theme.mediaQueries.height.m}) and (min-width: ${theme.mediaQueries.width.l}) {
        grid-column: ${({ hasPhoto }) => (hasPhoto ? '16 / 22' : '16 / 23')};
        grid-auto-columns: 21rem;
        grid-row: 1 / -1;
        max-height: 60%;
    }

    @media screen and (min-height: 1058px) {
        grid-column: ${({ hasPhoto }) => (hasPhoto ? '16 / 20' : '16 / 19')};
    }

    @media screen and (min-height: ${theme.mediaQueries.height.l}), (min-width: ${theme.mediaQueries.width.xl}) {
        max-height: 100%;
        grid-auto-columns: 21rem;
        grid-row: 4 / 28;
    }
`;

const Emerce = styled(Img)`
    width: 6.293rem;
    height: 4.8rem;
    object-fit: contain;
    margin-left: 2rem;
`;

const PhoneNumber = styled.a`
    text-decoration: none;
`;

export default Details;
