import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import { CTAFragment } from '../../entities/operationResults';
import { triggerGAEvent } from '../../utils/triggerGAEvent';
import BodyText from '../atoms/BodyText';
import Title from '../atoms/Title';
import C2aButton from '../icons/C2aButton';
import { getWizardComponent, getWizardSlug } from './CTA';

interface Props {
    title: string | null;
    anchor: string | null;
    cta: CTAFragment;
    hasPhoto: boolean | null;
    entryId: number;
}

const FooterText: React.FC<Props> = ({ title, anchor, cta, hasPhoto, entryId }) => {
    const [showPopover, setShowPopover] = useState(false);
    const Wizard = cta && getWizardComponent(cta.__typename);

    if (!('title' in cta)) {
        return null;
    }

    return (
        <Wrapper hasPhoto={hasPhoto}>
            <Title variant={hasPhoto ? 'medium' : 'huge'}>{title ? title : cta.ctaReason}</Title>
            <CTAWrapper
                className="footer_contact"
                onClick={() => {
                    setShowPopover(true);
                    triggerGAEvent('click', {
                        event_category: 'ConclusionContact',
                        event_label: cta.ctaReason,
                    });
                    if (cta.__typename !== 'Craft_CTALink') {
                        window.history.pushState({ opened: true }, '', `./${getWizardSlug(cta.__typename)}/`);
                    }
                }}
            >
                <C2aButton />

                <CtaActionText variant="tiny">{anchor ? anchor : cta.ctaSubtitle}</CtaActionText>
            </CTAWrapper>
            {Wizard && (
                <Wizard
                    variant="light"
                    showPopover={showPopover}
                    setShowPopover={setShowPopover}
                    data={cta}
                    sourceId={entryId}
                    withDelay
                />
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div<{ hasPhoto: boolean | null }>`
    z-index: 1;
    grid-row: 12 / 18;
    grid-column: ${({ hasPhoto }) => (hasPhoto ? `3 / 10` : `5 / 13`)};
    align-self: center;
    justify-self: center;
    @media screen and (min-height: ${theme.mediaQueries.height.m}) and (min-width: ${theme.mediaQueries.width.m}) {
        grid-column: ${({ hasPhoto }) => (hasPhoto ? `2 / 7` : `4 / 13`)};
    }

    @media screen and (min-height: ${theme.mediaQueries.height.l}) and (min-width: ${theme.mediaQueries.width.l}) {
        grid-column: ${({ hasPhoto }) => (hasPhoto ? `3 / 8` : `4 / 13`)};
        grid-row: 1 / -1;
    }
`;

const CTAWrapper = styled.a`
    margin-top: 2.8rem;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    color: ${theme.colors.black};
    transition: 250ms ease 0s;

    &:hover {
        transform: scale(1.02);
    }
`;

const CtaActionText = styled(BodyText)`
    vertical-align: middle;
    display: inline;

    @media screen and (${theme.mediaQueries.vertical}) {
        font-weight: 700;
    }
`;

export default FooterText;
