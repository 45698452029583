import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import { DirectContactFragment as DirectContact } from '../../entities/operationResults';
import Grid from '../atoms/Grid';
import ContactImage from '../molecules/ContactImage';
import Details from '../molecules/Details';
import FooterText from '../molecules/FooterText';

interface Props {
    data: DirectContact;
    hasFlatTop?: boolean;
    entryId: number;
}

export const directContactFragment = graphql`
    fragment DirectContactFragment on Craft_DirectContactSuperTableBlockType {
        id
        directContactTitle
        callToActionAnchor
        callToAction {
            ...CTAFragment
        }
        photo {
            ...TeamMemberPhotoFragment
        }
    }
`;

const Footer: React.FC<Props> = ({ data, hasFlatTop, entryId }) => {
    const { callToAction: ctas } = data;
    const photo = data.photo && data.photo[0];
    const cta = ctas && ctas[0];
    const directContactTitle = data && data.directContactTitle;
    const anchor = data && data.callToActionAnchor;

    return (
        <RoundedGrid>
            {photo && <ContactImage photo={photo} hasFlatTop={hasFlatTop} />}
            {cta && (
                <FooterText entryId={entryId} title={directContactTitle} anchor={anchor} cta={cta} hasPhoto={!!photo} />
            )}
            <Details hasPhoto={!!photo} />
        </RoundedGrid>
    );
};

const RoundedGrid = styled(Grid)`
    background: inherit;
    z-index: 0;

    @media screen and (${theme.mediaQueries.vertical}) {
        &::before {
            content: none;
        }
    }

    @media screen and (min-height: ${theme.mediaQueries.height.m}) and (min-width: ${theme.mediaQueries.width.m}) {
        &::before {
            width: 32rem;
            height: 130vh;
            margin-left: -20rem;
        }
    }

    @media screen and (min-height: ${theme.mediaQueries.height.l}) and (min-width: ${theme.mediaQueries.width.l}) {
        &::before {
            width: 55rem;
            height: 130vh;
            margin-left: -43rem;
        }
    }
`;

export default Footer;
