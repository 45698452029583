import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Twitter = ({ color = 'currentColor', ...rest }: Props) => (
    <svg width="27px" height="22px" viewBox="0 0 27 22">
        <title>Twitter</title>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Footer-person" transform="translate(-1587.000000, -745.000000)" fill="#191E21">
                <g id="Footer" transform="translate(-386.000000, -1706.000000)">
                    <g id="Group-5" transform="translate(1905.264828, 1972.000000)">
                        <g id="Group-3">
                            <g id="Group-4" transform="translate(1.735172, 436.000000)">
                                <path
                                    d="M74.5165337,65 C84.6715719,65 90.2259493,56.5222402 90.2259493,49.1703652 C90.2259493,48.929569 90.2259493,48.6898575 90.2098029,48.4512307 C91.2903568,47.6636656 92.2231091,46.6885155 92.9643866,45.571439 C91.9567182,46.0213609 90.8877698,46.3164196 89.7932246,46.4467655 C90.9458052,45.7514743 91.808434,44.6579018 92.2205736,43.3695643 C91.1367784,44.0176046 89.9510788,44.474311 88.714642,44.7199752 C87.0032078,42.8862304 84.2837551,42.4374149 82.0811967,43.6251987 C79.8786384,44.8129824 78.74074,47.3419694 79.3055696,49.7940489 C74.8662611,49.5697934 70.730166,47.4569414 67.9266308,43.9813167 C66.4612039,46.5233916 67.2097156,49.7754571 69.6360013,51.4080338 C68.757356,51.3817931 67.897868,51.1429554 67.1300728,50.7116774 C67.1300728,50.7344554 67.1300728,50.7583181 67.1300728,50.7821808 C67.130792,53.4304969 68.9834173,55.7114896 71.5595812,56.2358881 C70.7467364,56.4592645 69.8938929,56.4919176 69.0665699,56.3313388 C69.7898747,58.5976675 71.8626765,60.150218 74.2248213,60.1949238 C72.2697396,61.7432126 69.8545806,62.5837167 67.3679638,62.5811919 C66.9286767,62.5803421 66.4898168,62.553541 66.0536431,62.5009265 C68.5785554,64.1336508 71.5164308,64.999693 74.5165337,64.9956613"
                                    id="Path"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Twitter;
