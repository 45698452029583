import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Medium = ({ color = 'currentColor', ...rest }: Props) => (
    <svg width="29px" height="23px" viewBox="0 0 29 23">
        <title>Medium</title>
        <g id="Symbols" stroke="none" strokeWidth="1" fill={color} fillRule="evenodd">
            <g id="Footer-person" transform="translate(-1656.000000, -702.000000)">
                <g id="Footer" transform="translate(-386.000000, -1706.000000)">
                    <g id="Group-5" transform="translate(1905.264828, 1972.000000)">
                        <g id="Group-3">
                            <g id="Group-4" transform="translate(1.735172, 436.000000)">
                                <g
                                    id="Icon-/-Medium"
                                    style={{ mixBlendMode: 'multiply' }}
                                    transform="translate(135.511903, 1.101695)"
                                >
                                    <g id="medium-logo-3c124bcb05e120a8" transform="translate(0.000000, -1.050000)">
                                        <g id="Group" transform="translate(0.000000, 0.112001)">
                                            <path
                                                d="M19.0954762,0.0869464369 C18.6764286,-0.108463616 18.3333333,0.0894199819 18.3333333,0.534039691 L18.3333333,18.1271283 L25.9783333,21.735412 C26.8197024,22.1311792 27.5,21.8535238 27.5,21.1145523 L27.5,4.17200596 C27.4995353,4.10123222 27.4570218,4.03670286 27.39,4.00504167 L19.0954762,0.0869464369 Z"
                                                id="Path"
                                                fill="#AFAFAF"
                                            />
                                            <path
                                                d="M9.16666667,13.7953327 L17.8880952,0.413454373 C18.133631,0.0362387649 18.6757738,-0.108463616 19.0954762,0.0869464369 L27.4004762,4.00998876 C27.4541667,4.03657937 27.4718452,4.09903638 27.4410714,4.1478889 L18.3333333,18.1240364 L9.16666667,13.7953327 Z"
                                                id="Path"
                                                fill="#5F5F5F"
                                            />
                                            <path
                                                d="M1.26892857,0.328735458 C0.570297619,-0.00210118217 0.335238095,0.242161384 0.744464286,0.869823421 L9.16666667,13.7953327 L18.3333333,18.1240364 L9.18696429,4.08666866 C9.1735515,4.06744891 9.15503975,4.05188449 9.13327381,4.04152646 L1.26892857,0.328735458 Z"
                                                id="Path"
                                                fill="#272727"
                                            />
                                            <path
                                                d="M9.16666667,21.1145523 C9.16666667,21.8510503 8.59636905,22.1843605 7.8977381,21.8535238 L0.762797619,18.4839372 C0.343095238,18.2885271 6.06625861e-13,17.7641356 6.06625861e-13,17.3219894 L6.06625861e-13,0.799945776 C6.06625861e-13,0.210005299 0.458333333,-0.0552823991 1.01488095,0.208150141 L9.11821429,4.03410583 C9.1489881,4.04832871 9.16666667,4.07739287 9.16666667,4.10893056 L9.16666667,21.1145523 Z"
                                                id="Path"
                                                fill="#191E21"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Medium;
