import React from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import { TeamMemberPhotoFragment } from '../../entities/operationResults';
import { getRatio } from '../../utils/getRatio';
import Circle from '../atoms/Circle';
import Img from '../atoms/Img';

interface Props {
    photo: TeamMemberPhotoFragment | null;
    hasFlatTop?: boolean;
}

const ContactImage: React.FC<Props> = ({ photo, hasFlatTop }) => {
    return photo ? (
        <Wrapper hasFlatTop={hasFlatTop}>
            <ContactImg
                style={{ zIndex: 1 }}
                src={photo.url700 || undefined}
                alt={photo.title || undefined}
                srcSet={`${photo.url300} 800w, ${photo.url500} 1440w, ${photo.url700} 2000w`}
                ratio={getRatio(photo.height, photo.width) || 800 / 362}
            />
            <TeamMemberCircle diameter={70} fill={theme.colors.white} />
        </Wrapper>
    ) : null;
};

const Wrapper = styled.div<{ hasFlatTop?: boolean }>`
    place-items: center;
    position: relative;
    z-index: 0;
    grid-column: 10 / 20;
    grid-row: 4 / 30;

    @media screen and (min-height: ${theme.mediaQueries.height.m}) and (min-width: ${theme.mediaQueries.width.m}) {
        grid-column: 7 / 15;
        grid-row: 6 / 26;
    }

    @media screen and (min-height: ${theme.mediaQueries.height.l}) and (min-width: ${theme.mediaQueries.width.l}) {
        grid-row: 1 / -1;
        grid-column: 1 / 20;
    }

    @media (${theme.mediaQueries.vertical}) {
        padding-bottom: 3.2rem;
    }

    > figure {
        top: 50%;
        margin-left: 5vh;
        max-height: 90vh;
        z-index: 2;

        @media (${theme.mediaQueries.horizontal}) {
            transform: translateY(-50%);
        }

        @media (${theme.mediaQueries.vertical}) {
            padding-bottom: 5.2rem;
            max-height: 70vh;
            ${({ hasFlatTop }) => !hasFlatTop && `margin-top: -17rem`};
            top: 0;
            transform: translateY(0);

            &::before {
                padding-top: 0;
            }
        }

        @media screen and (min-height: ${theme.mediaQueries.height.m}) and (min-width: ${theme.mediaQueries.width.m}) {
            max-height: 70vh;
        }

        > img {
            object-fit: contain;
            object-position: center center;
        }
    }
`;

const ContactImg = styled(Img)`
    img {
        object-position: center center;
    }
    @media screen and (${theme.mediaQueries.vertical}) {
        padding-top: 100%;
    }
    @media screen and (${theme.mediaQueries.vertical}) and (max-height: 500px) {
        padding-top: 45%;
    }
`;

const TeamMemberCircle = styled(Circle)`
    @media (${theme.mediaQueries.vertical}) {
        display: none;
    }

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;

    @media screen and (min-height: ${theme.mediaQueries.height.m}) and (min-width: ${theme.mediaQueries.width.m}) {
        width: 50vh;
        height: 50vh;
    }
`;

export default ContactImage;
